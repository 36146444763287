import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'products',
    pathMatch: 'full'
  },

  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
   
  },

  {
    path: 'categories',
    children: [
      {
      path : '',
      loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule)
      },

      {
        path : ':catId',
        loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
      }
    ]
  },

  {
    path: 'tags',
    children: [
      {
      path : '',
      loadChildren: () => import('./pages/tags/tags.module').then( m => m.TagsPageModule)
      },

      {
        path : ':tagId',
        loadChildren: () => import('./pages/tag/tag.module').then( m => m.TagPageModule)
      }
    ]
  },

  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ordersuccess',
    loadChildren: () => import('./pages/ordersuccess/ordersuccess.module').then( m => m.OrdersuccessPageModule),
    canActivate: [AuthGuard]
  },
  
  { path: 'orders',
  children: [
    {
     path : '',
     loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule),
    canActivate: [AuthGuard]
    },

    {
     path : ':orderId',
     loadChildren: () => import('./pages/orderdetails/orderdetails.module').then( m => m.OrderdetailsPageModule),
     canActivate: [AuthGuard]
    }
    ]
   },
  {
    path: 'salesreport',
    loadChildren: () => import('./pages/salesreport/salesreport.module').then( m => m.SalesreportPageModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
